import {useState} from 'react';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useNavigate} from "react-router-dom";

import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";

import {sendPasswordResetEmail, getAuth} from "firebase/auth";

import FormContainer from '../../../../Components/Form/FormContainer'

import {PreloaderShortForm} from '../../../../Components/PreloaderForm'

import {getModule} from '../../../../Helpers/module'
import {saveUserHistoryAndNotify} from '../../Helpers/user';

import {useNavigationStore} from '../../../../Stores/navigation';
import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {setPageTitle} from '../../../../Helpers/router';

import PreloaderForm from '../../../../Components/PreloaderForm';
import { useEffect } from 'react';

import './index.scss';

export default function Reset(props) {
    const {embedded, onSuccess, onClose} = props;

    const {contentLoading} = useNavigationStore()
    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {showSuccess, showError} = useToasterStore();

    const [profile, setProfile] = useState();
    const [requestInProgres, setRequestInProgress] = useState();

    const auth = getAuth();
    
    const navigate = useNavigate();

    useEffect(() => {
        // setPageTitle('Відновити пароль');

        setRequestInProgress(true)

        getModule('profile')
            .then((profile) => {
                setProfile(profile);
                setRequestInProgress(false);
            })
    }, [])

    const onRestorePasswordSubmit = (data) => {
        if (!data.email) {
            showError({
                snackbarMessage: 'Вкажіть ваш email'
            });

            hideBackdropPreloader();
            return;
        }

        if (!data.email) return;

        sendPasswordResetEmail(auth, data.email)
            .then(async () => {
                const config = {
                    snackbarMessage: 'Посилання для оновлення пароля успішно відправлено на вашу електронну адресу',
                    snackbarDuration: 10000
                }

                if (!embedded) {
                    config.onCloseRedirect = '/'
                }

                showSuccess(config);

                await saveUserHistoryAndNotify(data.email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_SUCCESS);
                
                hideBackdropPreloader();

                if (embedded) {
                    onSuccess()
                }
            })
            .catch(async err => {
                console.log(err)
                if (err.code === 'auth/invalid-email') {
                    showError({
                        snackbarMessage: `${data.email} - не коректний email`
                    });
                } else {
                    showError({
                        snackbarMessage: 'Виникла помилка, спробуйте пізніше'
                    });
                }

                await saveUserHistoryAndNotify(data.email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_ERROR, err);

                hideBackdropPreloader();
            })
    }

    const onGoBack = () => {
        if (embedded) onClose()
        else navigate("/")
    }

    if (contentLoading) return <PreloaderForm />;

    return <div className="profileForm reset">
        {!embedded && <Typography variant='h4' className="pageTitle">
            <span className="goBack" onClick={onGoBack}>
                <ChevronLeftIcon />
            </span>
            <span>Відновити пароль</span>
        </Typography>}

        <p>Для відновлення вашого паролю:</p>
        <p>- вкажіть пошту на яку зареєстровано ваш аккаунт</p>
        <p>- очікуйте на листа з посиланням для зміни паролю</p>

        {requestInProgres && <PreloaderShortForm />}
        {!requestInProgres && <FormContainer
            config={profile?.restorePasswordForm}
            submitHandler={onRestorePasswordSubmit}
        />}
    </div>
}
