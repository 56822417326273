import {debounce, cloneDeep, isString} from 'lodash';

import React, {useEffect, useState, useRef, useMemo} from 'react';
import {isMobile} from "react-device-detect";

import {getClientCity} from 'ultra/configs/general';
import {normalizeRoute} from 'ultra/helpers/route';
import {DEFAULT_CHILDREN} from "ultra/const/general";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {useNavigationStore} from '../../../../Stores/navigation';

import ButtonWithPreloader from '../../../../Components/Form/ButtonWithPreloader';
import PreloaderTable from '../../../../Components/PreloaderTable';
import Preloader from '../../../../Components/Preloader';

import {getNodeContent} from '../../../../Helpers/content';

import Pagination from '../../Widgets/Pagination';

import CellContent from './CellContent';

import './index.scss';

function hasValues(item, tableOptions, fields) {
    if (!item) return false;

    let counter = 0;
    tableOptions?.hidden?.map((field) => {
        const config = fields?.[field];
        if (config?.type === 'node_image') {
            const {banner, poster, thumbnail} = fields;
            if (banner || poster || thumbnail) counter++;
        }
        else if (config?.type === 'location') {
            if (item[field]?.link?.length > 0) counter++;
        }
        else if (config?.type === 'url') {
            if (item[field]?.link?.length > 0) counter++;
        }
        else if (config?.type === 'messenger') {
            if (item[field] && Object.keys(item[field]).length > 0 && (item[field]?.telegram?.number || item[field]?.whatsup?.number)) counter++;
        }
        else if (config?.type === 'tags') {
            if (item[field]?.length > 0) counter++;
        }
        else if (item[field]) counter++;
    })

    return counter > 0;
}

function getFieldsWithValues(hidden, values, fields) {
    const result = []

    hidden?.map(field => {
        if (fields[field]?.type === 'location') {
            if (values[field]?.link) result.push(field)
        }
        else if (fields[field]?.type === 'url') {
            if (values[field]?.link) result.push(field)
        }
        else if (fields[field]?.type === 'messenger') {
            if (values[field] && Object.keys(values[field]).length > 0 && (values[field]?.telegram?.number || values[field]?.whatsup?.number)) result.push(field)
        }
        else if (fields[field]?.type === 'tags') {
            if (values[field]?.length) result.push(field)
        }
        else if (values[field]) {
            result.push(field)
        }
    })

    return result;
}

function DetailsPopupWrap(props) {
    if (hasValues(props.content?.list[props.id], props.tableOptions, props.fields)) {
        return <DetailsPopup {...props}/>
    } else {
        return <td></td>
    }
}

function DetailsPopup(props) {
    const {id, values, fields, tableOptions} = props;

    const [visible, setVisible] = useState(tableOptions?.unfolded ?  true : false);

    const fieldsWithValues = getFieldsWithValues(tableOptions?.hidden, values, fields)

    const isHidden = (!tableOptions?.hidden || tableOptions?.hidden.length === 0) || (fieldsWithValues?.length === 0);

    const handleClick = () => {
        if (isHidden) return;

        setVisible(!visible);
        document.getElementsByClassName(`${id}_item`)[0].classList.toggle("details_hidden");
        document.getElementsByClassName(`${id}_details`)[0].classList.toggle("visible");
    }

    if (!values) return <td></td>
    if (isHidden) return <td></td>;

    return <td className="detailsTD" onClick={handleClick}>
        {visible && <KeyboardArrowUpIcon />}
        {!visible && <KeyboardArrowDownIcon />}
    </td>
}

function Details(props) {
    const {id, values, fields, hidden, content, noChildLinks} = props;

    const [fieldsWithValues, setFieldsWithValues] = useState()

    useEffect(() => {
        setFieldsWithValues(getFieldsWithValues(hidden, values, fields))
    }, [])

    if (fieldsWithValues?.length === 0) {
        return <></>
    }

    return <>
        {fieldsWithValues?.map(field => <div className={`labelWrap ${field}`} key={`details_td_${id}_${field}`}>
            {fields[field]?.placeholder && <>
                <span className="label">{fields[field]?.placeholder}:</span>
                <span className="labelValue">
                    <CellContent
                        published={values._published}
                        noChildLinks={noChildLinks}
                        field={field}
                        fields={fields}
                        values={values}
                        content={content}
                    />
                </span>
            </>}
        </div>)}
    </>
}

export default function Table(props) {
    const {content, noDataText, showPreloader, showPagination, noChildLinks, actions, fields, tableOptions, selectClasses, scrollContainer, scrollGetData, topAlignVisibleData} = props;

    // const {contentLoading} = useNavigationStore()

    const [data, setData] = useState(content);
    const [displayedPages, setDisplayedPages] = useState([1])
    const [attachingInProgress, setAttachingInProgress] = useState(false)

    const paginationRef = useRef(null)

    function attachNext() {
        const lastUploaded = displayedPages.sort().reverse()[0]

        if (lastUploaded < data.pagination.pages) {
            const upload = lastUploaded + 1;
            displayedPages.push(upload);
            setDisplayedPages(displayedPages.sort());

            setAttachingInProgress(true);

            let url = new URL(window.location.href);
            url.searchParams.set('page', upload);
            url.searchParams.set('perPage', DEFAULT_CHILDREN.PER_PAGE);

            // get data by current uri or alternative function
            const city = getClientCity(window);
            const path = {
                city,
                uri: normalizeRoute(url.pathname)
            }

            let getData = (search) => {
                return getNodeContent(path, search)
            };

            if (scrollGetData) {
                getData = scrollGetData;
            }

            let container = window;
            if (scrollContainer) {
                container = scrollContainer()[0]
            }

            getData(url.search)
                .promise
                .then(attachData => {
                    const updated = cloneDeep(data);

                    let obj;
                    if (scrollGetData) {
                        obj = attachData;
                    }
                    else {
                        // get data from node children
                        obj = attachData.children;
                    }

                    updated.pagination.current = obj.pagination.current;

                    obj.order.map(uri => {
                        if (tableOptions?.infinityScrollReversed) {
                            updated.order.unshift(uri);
                        }
                        else {
                            updated.order.push(uri);
                        }

                        updated.list[uri] = {
                            ...obj.list[uri],
                            '__new_attached': true
                        }

                        // permits for nodes
                        if (!scrollGetData) updated.permits = obj.permits[uri];
                    })

                    // attach to results
                    setData(updated)

                    let contentHeight
                    if (tableOptions?.infinityScrollReversed) {
                        contentHeight = container.getElementsByClassName('FullHeightContentBlockContent')[0].offsetHeight
                        container.scrollTo(0, 120)
                    }

                    setTimeout(() => {
                        if (tableOptions?.infinityScrollReversed) {
                            let contentHeightUpdated = container.getElementsByClassName('FullHeightContentBlockContent')[0].offsetHeight
                            container.scrollTo(0, contentHeightUpdated - contentHeight)
                        }

                        updated.order.map(uri => {
                            if (updated.list[uri]['__new_attached']) delete updated.list[uri]['__new_attached'];
                        })

                        // attach to results
                        setData(updated)

                        // TODO: remove hidden class
                        setAttachingInProgress(false);
                    }, 200)
                })
        }
    }

    function getSelectionClass(data) {
        if (data && selectClasses) {
            return selectClasses(data)
        } else {
            return ''
        }
    }

    function hasDetails(hidden, data, fields) {
        return getFieldsWithValues(hidden, data, fields)?.length > 0;
    }

    function hasActions() {
        let result = false;
        data?.order?.map(id => {
            if (result) return;

            if (actions && actions(id)) {
                result = true;
            }
        })

        return actions;
    }

    const onScroll = useDebounce(() => {
        if (tableOptions?.infinityScroll) {

            const rect = paginationRef?.current?.getBoundingClientRect();
            const isVisible = (
                rect?.top >= 0 &&
                rect?.left >= 0 &&
                rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
            );

            if (isVisible && !attachingInProgress) {
                attachNext()
            }
        }
    }, 200)

    const handleClick = (id, hidden, values, fields) => {
        const fieldsWithValues = getFieldsWithValues(hidden, values, fields)
        const isHidden = (!hidden || hidden.length === 0) || (fieldsWithValues?.length === 0);

        if (tableOptions?.unfolded) return false;
        if (isHidden) return false;

        document.getElementsByClassName(`${id}_item`)[0].classList.toggle("details_hidden");
        document.getElementsByClassName(`${id}_details`)[0].classList.toggle("visible");
    }

    const onLoadMoreHandler = () => {
        attachNext()
    }

    useEffect(() => {
        let container = window;
        if (scrollContainer) {
            container = scrollContainer()[0]
        }
        container.removeEventListener('scroll', onScroll);
        container.addEventListener('scroll', onScroll, { passive: true });

        return () => container.removeEventListener('scroll', onScroll);
    }, [])

    useEffect(() => {
        if (content) setData(content)
    }, [content])

    return <>
        {fields && <div className={`TableWrap ${topAlignVisibleData ? 'topAlign' : ''}`}>
            <div className="TableAutoWrap">

            {/* contentLoading ||  */}
            {(showPreloader) && <PreloaderTable />}

            {tableOptions?.infinityScrollReversed && tableOptions?.infinityScroll && showPagination && data.pagination && <div ref={paginationRef}>
                {data.pagination.current < data.pagination.pages && <div className='InfinityPreloader'>
                    <Preloader />
                </div>}
            </div>}

            {/* !contentLoading &&  */}
            {!showPreloader && <table className='Table'>
                {!tableOptions?.noHeaders && <thead>
                    <tr>
                        {tableOptions?.visible.map((field) =><th key={`th_${field}`} className={`th_${field} th_${fields[field]?.type}`}>
                            {fields[field]?.placeholder}
                        </th>)}
                        {!isMobile && hasActions() && !tableOptions?.unfolded && <th></th>}
                        {tableOptions?.hidden?.length > 0 && <th className='DetailsBtn'></th>}
                    </tr>
                </thead>}
                <tbody>
                    {data?.order?.length === 0 && <tr>
                        <td colSpan={(tableOptions?.visible?.length || 0) + (tableOptions?.hidden?.length > 0 ? 1 : 0 )} className="noContent">
                            {!isString(noDataText) && !noDataText && 'Відсутні дані'}
                        </td>
                    </tr>}
                    {data
                        ?.order
                        ?.map((id, i) =>
                            <React.Fragment key={id}>
                                <tr key={`${id}_tr`}
                                    className={`
                                        item
                                        ${id}_item
                                        ${(tableOptions?.hidden?.length === 0) ? 'no_details' : ''}
                                        ${tableOptions?.hidden?.length > 0 ? 'has_details' : ''}
                                        ${!isMobile && actions && actions(id) ? 'has_actions' : ''}
                                        ${tableOptions?.unfolded ? '' : 'details_hidden'}
                                        ${tableOptions?.unfolded && !hasDetails(tableOptions?.hidden, data?.list?.[id], fields) ? 'no_details' : ''}
                                        ${i % 2 ? 'even ': 'odd '}
                                        ${data?.list?.[id]['__new_attached'] ? '__new_attached' : ''}
                                        ${getSelectionClass(data?.list?.[id])}`}
                                >
                                    {tableOptions?.visible.map((field, j) => <td key={`td_${id}_${field}`} className={`td_${field} td_${fields[field]?.type}`} onClick={() => handleClick(id, tableOptions?.hidden, data.list[id], fields)}>
                                        <CellContent
                                            field={field}
                                            fields={fields}
                                            published={data?.list?.[id]?._published}
                                            noChildLinks={noChildLinks}
                                            values={data?.list?.[id]}
                                            content={data}
                                        />
                                    </td>)}

                                    {!isMobile && actions && actions(id) && tableOptions?.visible && <td className="ActionsCell">
                                        {actions(id)}
                                    </td>}

                                    {tableOptions?.hidden?.length > 0 && !tableOptions?.unfolded && <DetailsPopupWrap
                                        id={id}
                                        content={data}
                                        values={data?.list[id]}
                                        tableOptions={tableOptions}
                                        fields={fields}/>}
                                </tr>

                                {hasValues(data.list[id], tableOptions, fields)
                                && <tr
                                    key={`${id}_details`}
                                    className={`
                                        ${id}_details details
                                        ${i % 2 ? 'even': 'odd'}
                                        ${getSelectionClass(data?.list?.[id])}
                                        ${tableOptions?.unfolded ? 'visible' : ''}
                                    `}
                                    >
                                        <td colSpan={(tableOptions?.visible?.length || 0) + (tableOptions?.hidden?.length > 0 ? 1 : 0 )} className="Details">
                                            <Details
                                                id={id}
                                                values={data?.list?.[id]}
                                                hidden={tableOptions?.hidden}
                                                fields={fields}
                                                content={data}
                                                noChildLinks={noChildLinks}
                                            />

                                            {isMobile && actions && actions(id)}
                                        </td>
                                        {!isMobile && actions && actions(id) && !tableOptions?.unfolded && <td></td>}
                                </tr>}
                            </React.Fragment>
                        )}
                </tbody>
            </table>}
            
            {showPagination && data?.pagination && <>
                {!tableOptions?.infinityScroll && !tableOptions?.loadMoreButton && <Pagination pagination={data.pagination} />}

                {tableOptions?.infinityScroll && !tableOptions?.infinityScrollReversed && <div ref={paginationRef}>
                    {data.pagination.current < data.pagination.pages && <div className='InfinityPreloader'>
                        <Preloader />
                    </div>}
                </div>}

                {tableOptions?.loadMoreButton && data.pagination.current < data.pagination.pages && <div className='loadMoreButton'>
                    <ButtonWithPreloader variant="outlined" fullWidth onClick={onLoadMoreHandler} inprogress={attachingInProgress.toString()}>
                        Показати ще
                    </ButtonWithPreloader>
                </div>}
            </>}
            </div>
        </div>}
    </>
}


const useDebounce = (callback, timer) => {
    const ref = useRef();
  
    useEffect(() => {
      ref.current = callback;
    }, [callback]);
  
    const debouncedCallback = useMemo(() => {
      const func = () => {
        ref.current?.();
      };
  
      return debounce(func, timer);
    }, []);
  
    return debouncedCallback;
};
