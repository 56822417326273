import {useState, useEffect, useCallback} from 'react';
import {isMobile} from "react-device-detect";
import {useLocation, useNavigate} from "react-router-dom";
import {revertPath} from 'ultra/helpers/route';

import {isObject, isUndefined} from 'lodash';
import {signOut, getAuth} from "firebase/auth";

import {getClientCity, getCityConfig} from 'ultra/configs/general';
import {isEmptyObj} from 'ultra/helpers/utils';

import Skeleton from '@mui/material/Skeleton';
import Badge from '@mui/material/Badge';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import Avatar from '../../Widgets/Avatar';
import Footer from '../../Widgets/Footer';
import SelectCity from '../../Widgets/SelectCity';

import AdaptiveMenu from '../../../../Components/Adaptive/Menu';
import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

// import {getTelegramUser} from '../../../../Helpers/telegram';
import {getNodeContent} from '../../../../Helpers/content';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import SignIn from '../../../Profile/Pages/SignIn'

import {getUserDetails, userLogout} from '../../../Profile/Helpers/user';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import Preloader from '../../../../Components/Preloader';

import UserLinks from './UserLinks';
import UserLinksHeader from './UserLinksHeader';

import './index.scss'

export default function UserPic(props) {
  const {configs} = props;

  const auth = getAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const {showError} = useToasterStore();
  const {userState, userDispatch} = useGlobalCustomContext();

  const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()

  const [anchorUserMenu, setAnchorUserMenu] = useState(null);
  // const [telegram] = useState(getTelegramUser());

  const [income, setIncome] = useState();
  
  const [openUserMenu, setOpenUserMenu] = useState(false);

  // disconnect
  useEffect(() => {
    if (userState?.userRefreshStarted) {
      // prevent double tap
      userDispatch({type: USER_ACTIONS.USER_REFRESH_STOP});

      fetchUserData()
        .catch(getUserDataHandlerError);
    }
  }, [userState?.userRefreshStarted])

  useEffect(() => {
    fetchUserData()
      .catch(getUserDataHandlerError);
  }, [])

  function getUserDataHandlerError(err) {
    console.error(err);
  }

  const fetchUserData = useCallback(async () => {
    // if user undefined it means authorisation in progress
    // or was logout
    userDispatch({type: USER_ACTIONS.USER_UPDATE_IN_PROGRESS});
    getUserDetails()
      .promise
      .then(user => {
        if (user) {
          userDispatch({type: USER_ACTIONS.USER_UPDATE, data: {user}});

          let additionalNotifications = 0;
          if (user._not_checked) additionalNotifications++;
          if (user.emailNotVerified) additionalNotifications++;

          setIncome(user.income + additionalNotifications);
        } else {
          userDispatch({type: USER_ACTIONS.USER_REMOVE});
        }
      })
      .catch((err) => {
        userDispatch({type: USER_ACTIONS.USER_REMOVE});

        if (userState?.user && err.tokenExpired) {
          showError({snackbarMessage: 'Ви авторизовались понад 7 днів тому. Будь ласка, авторизуйтесь повторно'});
        }
        else {
          if (err.status === 401) {
            console.error(err)

            // if 401 returned, empty object setup. it means non authorised user
            userDispatch({type: USER_ACTIONS.USER_REMOVE});
          }
        }
      })
  })

  const onUserPicHandler = (event) => {
    setAnchorUserMenu(event.currentTarget)
    setOpenUserMenu(true)
  }

  const handleUserMenuClose = () => {
    // userDispatch({type: USER_ACTIONS.PROFILE_EDIT_CLOSE});
    setOpenUserMenu(false)
  }

  const onProfileHandler = () => {
    userDispatch({type: USER_ACTIONS.LOGIN})
  }

  const handleLoginClose = () => {
    userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
  };

  const onLoginSuccess = () => {
    userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
    navigate(location.pathname + location.search);
    userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
  }

  // TODO: make one function / has duplicate
  const onExitHandler = () => {
    handleUserMenuClose();
    showBackdropPreloader();

    userLogout()
      .promise
      .then(async () => {
          signOut(auth);

          userDispatch({type: USER_ACTIONS.USER_REMOVE});

          setOpenUserMenu(false);

          hideBackdropPreloader();

          const isProfilePage = location.pathname.indexOf('/profile') === 0

          if (isProfilePage) {
            navigate('/');
          }
          else {
            const city = getClientCity(window);
            
            try {
              await getNodeContent({
                city,
                uri: revertPath(location.pathname)
              }).promise

              navigate(location.pathname + location.search);
            }
            catch (e) {
              navigate('/');
            }
          }

          window.localStorage.removeItem("verify-email-msg-displayed");
      })
      .catch(() => {
        userDispatch({type: USER_ACTIONS.USER_REMOVE});
      })
  }

  // if (userState?.userLoginInProgress) {
  //   return <div className='UserPic user'>
  //     <Skeleton variant="circular" width={30} height={30} />
  //   </div>
  // }

  const city = getClientCity(window);
  const cityConfig = getCityConfig(configs, city);

  // if use is tempty object it means user not authorised
  // !userState?.user || 
  //  && !userState?.userLoginInProgress
  // if (telegram) return <div className='userArea'>
  //   <span className='iconsSet'>
  //     <span className='AvatarWrap AvatarMinimal'>
  //       {telegram.photoURL && <img className='Avatar' src={telegram.photoURL} />}
  //     </span>
  //   </span>

  //   <SelectCity config={cityConfig} />
  // </div>

  if (window.location.pathname === '/profile/login' || window.location.pathname === '/profile') {
    return <></>
  }

  if (isUndefined(userState?.user)) {
    return <div className='UserPic user'>
      <Skeleton variant="circular" width={30} height={30} />
    </div>
  }

  else if ((isObject(userState?.user) && isEmptyObj(userState?.user))) {
    return <div className='UserPic user'>

      {<div className='userArea loginLink'>
        <span className='iconsSet'>
          {userState?.userUpdateInProgress && <span className='preloader'><Preloader /></span>}
          <span className='loginIcon' onClick={onProfileHandler}><AccountCircleOutlinedIcon /></span>
        </span>

        <SelectCity config={cityConfig} />
      </div>}

      <AdaptiveDialog open={Boolean(userState?.loginPopupOpen)} onClose={handleLoginClose} title="Мій Простір">
        <SignIn onSuccess={onLoginSuccess} />
        {isMobile && <Footer />}
      </AdaptiveDialog>
    </div>
  }

  return <div className='UserPic user'>

    {userState?.user &&
      <div className='userArea'>
        <span className='iconsSet'>
          <Badge
            color='error'
            badgeContent={income}
            onClick={onUserPicHandler}
          >
            {userState?.userUpdateInProgress && <span className='preloader'><Preloader /></span>}
            <Avatar image={userState?.user?.photoURL} roles={userState?.user?.roles} minimal="true" />
          </Badge>
        </span>

        <SelectCity config={cityConfig} />

        <AdaptiveMenu
          open={openUserMenu}
          onClose={handleUserMenuClose}
          anchorMenu={anchorUserMenu}
          className="UserPicLinks"
          title="Мій Простір"
          links={<UserLinks closeMenu={handleUserMenuClose} onExit={onExitHandler} />}
          header={<UserLinksHeader closeMenu={handleUserMenuClose} />}
          footer={isMobile ? <Footer /> : <></>}
        />
      </div>
    }
  </div>
}
